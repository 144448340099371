import { useState, useContext } from 'react';
import axios from 'axios';
import { FacebookContext } from '../../context/FacebookContext';
const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;

export function FacebookInit() {
	const usingContext = useContext(FacebookContext);

	const [userToken, setUserToken] = useState({});

	new Promise((resolve) => {
		// wait for facebook sdk to initialize before starting the react app
		window.fbAsyncInit = function () {
			window.FB.init({
				appId: facebookAppId,
				cookie: true,
				xfbml: true,
				version: 'v8.0',
			});

			// auto authenticate with the api if already logged in with facebook
			window.FB.getLoginStatus(({ authResponse }) => {
				if (authResponse) {
					console.log('Loggied from init');
					setUserToken(authResponse);
				} else {
					resolve();
				}
			});
		};
		// load facebook sdk script
		(function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			js = d.createElement(s);
			js.id = id;
			js.src = 'https://connect.facebook.net/en_US/sdk.js';
			fjs.parentNode.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
	});
}
